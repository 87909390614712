import React, { useState, useEffect, useRef, useCallback } from "react";
import "./App.css";
import qpcore from "./qpcore.png"; // Импорт изображения
import tgIcon from "./tg.png"; // Импорт иконок
import ytIcon from "./YT.png";
import xIcon from "./x.png";
import backIcon from './back_icn.png';

// Уникальные идентификаторы для месяцев
const monthIds = {
  September: 'july2024',
  October: 'august2024',
  November: 'september2024',
  December: 'october2024'
};

const steps = [
  { title: "Welcome to", subtitle: "qp Network", description: "This is your way." },
  { title: "Roadmap", subtitle: "", description: "" },
  { title: "September 2024", subtitle: "", description: "", id: monthIds.September },
  { title: "First 2 Weeks:", subtitle: "", description: "" },
  { title: "Launch of Farming qp Coin", subtitle: "", description: "Implement the qp Coin farming system." },
  { title: "Launch of Referral Program", subtitle: "", description: "Introduce a program to incentivize user referrals." },
  { title: "Scheduled Load Testing", subtitle: "", description: "Conduct load testing to ensure system stability under high traffic." },
  { title: "Next 2 Weeks:", subtitle: "", description: "" },
  { title: "Increase qp Coin Farming by 20% per Round", subtitle: "", description: "Temporarily boost farming rewards by 20%." },
  { title: "Implement Social Quests", subtitle: "", description: "Introduce quests for users to follow on Twitter, Telegram, and YouTube." },
  { title: "October 2024", subtitle: "", description: "", id: monthIds.October },
  { title: "First 2 Weeks:", subtitle: "", description: "" },
  { title: "Remove 20% Farming Boost", subtitle: "", description: "Revert farming rewards to the original rate." },
  { title: "Add Daily Rewards", subtitle: "", description: "Introduce daily login rewards to increase user engagement." },
  { title: "Next 2 Weeks:", subtitle: "", description: "" },
  { title: "Add Clans", subtitle: "", description: "Implement a clan system for users to join." },
  { title: "Introduce 'Partnership' Feature", subtitle: "", description: "Allow users to form partnerships within the app." },
  { title: "Add Partner Rewards", subtitle: "", description: "Integrate rewards provided by partners." },
  { title: "Implement Partner Quests", subtitle: "", description: "Introduce quests and tasks provided by partners." },
  { title: "November 2024", subtitle: "", description: "", id: monthIds.November },
  { title: "First 2 Weeks:", subtitle: "", description: "" },
  { title: "50% Halving", subtitle: "", description: "Reduce the qp Coin production rate by 50%." },
  { title: "Introduce Squad Battles", subtitle: "", description: "Add a feature for battles between user squads." },
  { title: "Leaderboard Implementation", subtitle: "", description: "Create leaderboards for squads and individual users." },
  { title: "Next 2 Weeks:", subtitle: "", description: "" },
  { title: "Bridge Integration", subtitle: "", description: "Integrate the bridge into the website and app." },
  { title: "User Testing of the Bridge", subtitle: "", description: "Conduct testing with users to ensure functionality." },
  { title: "Double Balance Proposal", subtitle: "", description: "Offer users the option to double their balance." },
  { title: "Reset Coin Farming Cycle", subtitle: "", description: "Reset the coin farming cycle to the initial amount of coins mined during the first month." },
  { title: "December 2024", subtitle: "", description: "", id: monthIds.December },
  { title: "Closure of Balance Doubling Proposal", subtitle: "", description: "End the double balance offer." },
  { title: "Wallet Integration", subtitle: "", description: "Implement wallet functionality for users." },
  { title: "NFT Collection for Bridge Testers", subtitle: "", description: "Issue an NFT collection to users who participated in the bridge test." },
  { title: "Network Launch", subtitle: "", description: "Launch the qp Network." },
  { title: "Listing", subtitle: "", description: "List qp Coin on major cryptocurrency exchanges." }
];

const additionalSteps = [
  { title: "Open Telegram Bot", subtitle: "", description: "", buttonText: "Follow The Cat", buttonUrl: "https://t.me/qpcore_bot", id: 'openTelegramBot' },
  { title: "Network", subtitle: "", description: "In December 2024.", id: 'network' }
];

function App() {
  const [currentStep, setCurrentStep] = useState(0); // Начнем с показа первого блока
  const [selectedMonth, setSelectedMonth] = useState(null);
  const stepRefs = useRef([]);
  const additionalStepRefs = useRef([]);
  const [scrolling, setScrolling] = useState(false);
  const touchStartY = useRef(0);
  const touchEndY = useRef(0);

  useEffect(() => {
    stepRefs.current = stepRefs.current.slice(0, steps.length);
    additionalStepRefs.current = additionalStepRefs.current.slice(0, additionalSteps.length);
  }, []);

  const handleScroll = useCallback((event) => {
    if (scrolling) return;

    const delta = Math.sign(event.deltaY);
    setScrolling(true);

    setCurrentStep((prevStep) => {
      if (delta > 0 && prevStep < 1) {
        return 1;
      } else if (delta < 0 && prevStep === 1) {
        return 0;
      } else if (delta > 0 && prevStep === 1 && !selectedMonth) {
        return 2;
      } else if (delta < 0 && prevStep === 2 && !selectedMonth) {
        return 1;
      } else if (delta > 0 && prevStep === 2 && !selectedMonth) {
        return 3;
      } else if (delta < 0 && prevStep === 3 && !selectedMonth) {
        return 2;
      } else if (delta > 0 && selectedMonth) {
        const monthStartIndex = steps.findIndex(step => step.id === monthIds[selectedMonth]);
        const monthEndIndex = steps.findIndex((step, index) => index > monthStartIndex && step.id) - 1;

        // Check if we are at the last step of the month
        if (prevStep === monthEndIndex || (monthEndIndex === -2 && prevStep === steps.length - 1)) {
          return monthStartIndex;
        } else {
          return Math.min(prevStep + 1, steps.length - 1);
        }
      } else if (delta < 0 && selectedMonth) {
        const monthStartIndex = steps.findIndex(step => step.id === monthIds[selectedMonth]);
        if (prevStep === monthStartIndex) {
          const monthEndIndex = steps.findIndex((step, index) => index > monthStartIndex && step.id) - 1;
          // Check if we are at the first step of the month
          return monthEndIndex === -2 ? steps.length - 1 : monthEndIndex;
        } else {
          return Math.max(prevStep - 1, 0);
        }
      }
      return prevStep;
    });

    setTimeout(() => setScrolling(false), 1500); // Увеличена задержка для плавности
  }, [scrolling, selectedMonth]);

  const handleTouchStart = useCallback((event) => {
    touchStartY.current = event.touches[0].clientY;
  }, []);

  const handleTouchMove = useCallback((event) => {
    touchEndY.current = event.touches[0].clientY;
  }, []);

  const handleTouchEnd = useCallback((event) => {
    touchEndY.current = event.changedTouches[0].clientY;
    if (scrolling) return;

    const delta = touchStartY.current - touchEndY.current;
    setScrolling(true);

    setCurrentStep((prevStep) => {
      if (delta > 0 && prevStep < 1) {
        return 1;
      } else if (delta < 0 && prevStep === 1) {
        return 0;
      } else if (delta > 0 && prevStep === 1 && !selectedMonth) {
        return 2;
      } else if (delta < 0 && prevStep === 2 && !selectedMonth) {
        return 1;
      } else if (delta > 0 && prevStep === 2 && !selectedMonth) {
        return 3;
      } else if (delta < 0 && prevStep === 3 && !selectedMonth) {
        return 2;
      } else if (delta > 0 && selectedMonth) {
        const monthStartIndex = steps.findIndex(step => step.id === monthIds[selectedMonth]);
        const monthEndIndex = steps.findIndex((step, index) => index > monthStartIndex && step.id) - 1;

        // Check if we are at the last step of the month
        if (prevStep === monthEndIndex || (monthEndIndex === -2 && prevStep === steps.length - 1)) {
          return monthStartIndex;
        } else {
          return Math.min(prevStep + 1, steps.length - 1);
        }
      } else if (delta < 0 && selectedMonth) {
        const monthStartIndex = steps.findIndex(step => step.id === monthIds[selectedMonth]);
        if (prevStep === monthStartIndex) {
          const monthEndIndex = steps.findIndex((step, index) => index > monthStartIndex && step.id) - 1;
          // Check if we are at the first step of the month
          return monthEndIndex === -2 ? steps.length - 1 : monthEndIndex;
        } else {
          return Math.max(prevStep - 1, 0);
        }
      }
      return prevStep;
    });

    setTimeout(() => setScrolling(false), 1500); // Увеличена задержка для плавности
  }, [scrolling, selectedMonth]);

  useEffect(() => {
    window.addEventListener("wheel", handleScroll);
    window.addEventListener("touchstart", handleTouchStart);
    window.addEventListener("touchmove", handleTouchMove);
    window.addEventListener("touchend", handleTouchEnd);
    return () => {
      window.removeEventListener("wheel", handleScroll);
      window.removeEventListener("touchstart", handleTouchStart);
      window.removeEventListener("touchmove", handleTouchMove);
      window.removeEventListener("touchend", handleTouchEnd);
    };
  }, [handleScroll, handleTouchEnd, handleTouchMove, handleTouchStart]);

  const handleMonthClick = (month) => {
    const monthId = monthIds[month];
    setSelectedMonth(month);
    if (monthId) {
      const element = document.getElementById(monthId);
      if (element) {
        element.scrollIntoView({ behavior: "smooth" });
        setCurrentStep(steps.findIndex(step => step.id === monthId));
      }
    }
  };

  useEffect(() => {
    if (selectedMonth) {
      const index = steps.findIndex(step => step.id === monthIds[selectedMonth]);
      setCurrentStep(index);
    }
  }, [selectedMonth]);

  const renderSteps = () => {
    if (currentStep === 0) {
      return (
        <div
          key={0}
          className={`content visible`}
          id={`step-0`}
          ref={el => stepRefs.current[0] = el}
        >
          <div className="coin-container">
            <img src={qpcore} alt="qp Core" className="coin" />
          </div>
          <h1 className="slide-down">{steps[0].title}</h1>
          <h2 className="slide-down">{steps[0].subtitle}</h2>
          <p className="slide-up">{steps[0].description}</p>
        </div>
      );
    } else if (currentStep === 1 && !selectedMonth) {
      return (
        <div
          key={1}
          className={`content visible`}
          id={`step-1`}
          ref={el => stepRefs.current[1] = el}
        >
          <h1 className="slide-down">{steps[1].title}</h1>
          <h2 className="slide-down">{steps[1].subtitle}</h2>
          <p className="slide-up">{steps[1].description}</p>
          <div className="months">
            <div className="months-column">
              {["September", "October"].map((month) => (
                <button
                  key={month}
                  className="month-button slide-up"
                  onClick={() => handleMonthClick(month)}
                >
                  {month}
                </button>
              ))}
            </div>
            <div className="months-column">
              {["November", "December"].map((month) => (
                <button
                  key={month}
                  className="month-button slide-up"
                  onClick={() => handleMonthClick(month)}
                >
                  {month}
                </button>
              ))}
            </div>
          </div>
        </div>
      );
    } else if (currentStep === 2 && !selectedMonth) {
      return (
        <div
          key={2}
          className={`content visible telegram-bot`}
          id={`step-2`}
          ref={el => additionalStepRefs.current[0] = el}
        >
          <h1 className="slide-down">{additionalSteps[0].title}</h1>
          <h2 className="slide-down">{additionalSteps[0].subtitle}</h2>
          <button
            onClick={() => window.open(additionalSteps[0].buttonUrl, "_blank")}
            className="bot-button slide-up"
          >
            {additionalSteps[0].buttonText}
          </button>
        </div>
      );
    } else if (currentStep === 3 && !selectedMonth) {
      return (
        <div
          key={3}
          className={`content visible`}
          id={`step-3`}
          ref={el => additionalStepRefs.current[1] = el}
        >
          <h1 className="slide-down">{additionalSteps[1].title}</h1>
          <h2 className="slide-down">{additionalSteps[1].subtitle}</h2>
          <p className="slide-up">{additionalSteps[1].description}</p>
        </div>
      );
    } else if (selectedMonth) {
      const monthId = monthIds[selectedMonth];
      const startIndex = steps.findIndex(step => step.id === monthId);
      const endIndex = steps.findIndex((step, index) => index > startIndex && step.id);
      const stepsToRender = steps.slice(startIndex, endIndex !== -1 ? endIndex : undefined);
      return (
        <>
          {stepsToRender.map((step, index) => {
            const currentIndex = startIndex + index;
            const isVisible = currentStep === currentIndex;
            return (
              <div
                key={currentIndex}
                className={`content ${isVisible ? "visible" : ""}`}
                id={step.id ? step.id : `step-${currentIndex}`}
                ref={el => stepRefs.current[currentIndex] = el}
              >
                <h1 className={`${isVisible ? "slide-down" : ""}`}>{step.title}</h1>
                <h2 className={`${isVisible ? "slide-down" : ""}`}>{step.subtitle}</h2>
                <p className={`${isVisible ? "slide-up" : ""}`}>{step.description}</p>
              </div>
            );
          })}
        </>
      );
    } else {
      return steps.map((step, index) => {
        const isVisible = currentStep === index;
        return (
          <div
            key={index}
            className={`content ${isVisible ? "visible" : ""}`}
            id={step.id ? step.id : `step-${index}`}
            ref={el => stepRefs.current[index] = el}
          >
            <h1 className={`${isVisible ? "slide-down" : ""}`}>{step.title}</h1>
            <h2 className={`${isVisible ? "slide-down" : ""}`}>{step.subtitle}</h2>
            <p className={`${isVisible ? "slide-up" : ""}`}>{step.description}</p>
          </div>
        );
      });
    }
  };

const handleBackClick = (index) => {
  setSelectedMonth(null); // Сбросить выбранный месяц
  setCurrentStep(1); // Устанавливает текущий шаг на Roadmap
};

const shouldShowBackIcon = (index) => {
  // Array of indices where the back button should be shown
  const validSteps = [
    steps.findIndex(step => step.id === monthIds.September),
    3, 7, // September
    steps.findIndex(step => step.id === monthIds.October),
    11, 14, // October
    steps.findIndex(step => step.id === monthIds.November),
    20, 24, // November
    29, // December
    steps.findIndex(step => step.title === "Launch of Farming qp Coin"),
    steps.findIndex(step => step.title === "Launch of Referral Program"),
    steps.findIndex(step => step.title === "Scheduled Load Testing"),
    steps.findIndex(step => step.title === "Increase qp Coin Farming by 20% per Round"),
    steps.findIndex(step => step.title === "Implement Social Quests"),
    steps.findIndex(step => step.title === "Remove 20% Farming Boost"),
    steps.findIndex(step => step.title === "Add Daily Rewards"),
    steps.findIndex(step => step.title === "Add Clans"),
    steps.findIndex(step => step.title === "Introduce 'Partnership' Feature"),
    steps.findIndex(step => step.title === "Add Partner Rewards"),
    steps.findIndex(step => step.title === "Implement Partner Quests"),
    steps.findIndex(step => step.title === "50% Halving"),
    steps.findIndex(step => step.title === "Introduce Squad Battles"),
    steps.findIndex(step => step.title === "Leaderboard Implementation"),
    steps.findIndex(step => step.title === "Bridge Integration"),
    steps.findIndex(step => step.title === "User Testing of the Bridge"),
    steps.findIndex(step => step.title === "Double Balance Proposal"),
    steps.findIndex(step => step.title === "Reset Coin Farming Cycle"),
    steps.findIndex(step => step.title === "Closure of Balance Doubling Proposal"),
    steps.findIndex(step => step.title === "Wallet Integration"),
    steps.findIndex(step => step.title === "NFT Collection for Bridge Testers"),
    steps.findIndex(step => step.title === "Network Launch"),
    steps.findIndex(step => step.title === "Listing")
  ];

  // Array of indices where the back button should not be shown
  const excludedSteps = [
    additionalSteps.findIndex(step => step.id === 'openTelegramBot'),
    additionalSteps.findIndex(step => step.id === 'network')
  ];

  return validSteps.includes(index) && !excludedSteps.includes(index);
};

return (
  <div className="App">
    <header className="App-header">
      {renderSteps()}
    </header>
    <div className="icon-container">
      <a href="https://telegram.org" target="_blank" rel="noopener noreferrer">
        <img src={tgIcon} alt="Telegram" />
      </a>
      <a href="https://youtube.com" target="_blank" rel="noopener noreferrer">
        <img src={ytIcon} alt="YouTube" />
      </a>
      <a href="https://twitter.com" target="_blank" rel="noopener noreferrer">
        <img src={xIcon} alt="X" />
      </a>
    </div>
    {shouldShowBackIcon(currentStep) && (
      <img
        src={backIcon}
        alt="Back"
        className="back-icon visible"
        onClick={() => handleBackClick(currentStep)}
      />
    )}
  </div>
);
}

export default App;

